import React from 'react';
import Game from '../lib/Game';
import ScoringStyle from '../lib/ScoringStyle';

export default class SelectPlayersTeamTabs extends React.Component {

    evalTeamNameDisplay = (team) => {
        if (this.props.game.scoringStyle === 'Team Total') {
            return team.name
        } else if (this.props.game.scoringStyle === 'Lane Total') {
            return 'Scores Tracked as Lane'
        } else {
            return 'Scores Tracked Individually'
        }
    }

    renderTeamTabs = () => {
        return this.props.game.gameState.map((team, index) => {
            return (
                <div
                    key={index}
                    className={`btn ${this.props.game.selectPlayersActiveTeam === team.id ? 'btn-gold active' : 'btn-default'}`}
                    onClick={() => this.selectTeam(team)}>
                    {this.evalTeamNameDisplay(team)}
                </div>
            )
        })
    }

    selectTeam = (team) => {
        Game.setActiveTeam(team.id);
    }

    render = () => {
        return (
            <div className={`btn-${ScoringStyle.styleRequiresTeams(this.props.game.scoringStyle) ? 'group' : 'full'}`}>
                {this.renderTeamTabs()}
            </div>
        );
    }
}