import React from 'react';
import Display from '../lib/Display';
import Request from '../lib/Request';
import Endpoints from '../lib/Endpoints';

export default class EmailScores extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emails: ''
        }
    }

    async sendMail(scoringHtml) {
        const validatePromise = await Request.post(Endpoints.sendMail(this.props.gameId, this.state.emails), {
            scoring_html: scoringHtml
        });
        return validatePromise;
    }

    updateEmails = (e) => this.setState({ emails: e.target.value });

    close = () => {
        Display.toggleEndGameModal(true);
        Display.toggleLeagueScoreboard(false);
    }

    mailScores = () => {
        let scoreHTML = document.getElementById("score-grid").outerHTML;
        scoreHTML = encodeURI("<div class=scoreboard scoreboard-league active>" + scoreHTML + "</div>");
        console.log(scoreHTML);
        this.sendMail(scoreHTML).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.close();
            } else {
                alert('There was a problem sending the scores. Please try again');
            }
        });
    }

    render() {
        return (
            <div className='email-scores'>
                <div className="field-container team-name-1">
                    <div className="input-group">
                        <label>Emails (comma-separated)</label>
                        <input
                            type="text"
                            name='team1'
                            onChange={this.updateEmails}
                            value={this.state.team1} 
                            autoComplete="off" />
                    </div>
                    <div className="actions">
                        <div className="btn btn-outline btn-cancel" onClick={this.close}>Cancel</div>
                        <div className="btn btn-gold btn-save" onClick={this.mailScores}>Email Scores</div>
                    </div>
                </div>
            </div>
        )
    }
}