import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';
import Actions from '../lib/Actions';
import SelectPlayersTeamTabs from './SelectPlayersTeamTabs';
import GameSetupSelectedPlayers from './GameSetupSelectedPlayers';
import Dispatcher from '../dispatchers/Dispatcher';
// import Reservation from '../lib/Reservation';
import Request from '../lib/Request';
import Endpoints from '../lib/Endpoints';
import Spinner from './Spinner';

export default class GameSetupSelectPlayers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            loading: false
        };
    }
    
    validatePlayers = () => {
        if (this.props.game.players.length >= 1) {
            Game.storeTeamSetup();
            Display.togglePlayerSetup(false);
            Game.toggleReadyToPlay(true);
            Game.setInitialThrower(this.props.game.gameState);
            // TODO: Update current team with new scoring data for each game
            Game.setCurrentTeam({
                id: Game.getInitialTeamId(this.props.game.gameState),
                singleScore: this.props.game.gameState[0].singleScore,
                bucketList: this.props.game.gameState[0].bucketList,
                magicNumber: this.props.game.gameState[0].magicNumber,
                throwCount: 0
            });

            // TODO: this is way too weaponized to be a final solution. We have to
            // wait until the dispatch and props get set before we can actually
            // post data.
            setTimeout( () => {
                Game.sendGameState(this.props.app, this.props.game);
            }, 1500);
            

            // This game doesn't use the target interface so we want to just directly render the scoreboard
            // TODO: basically screwed... Update for tic tac toe
            // if (this.props.game.game === 'Magic Number') {
            //     Display.toggleLeagueScoreboard(true)
            // }
        } else {
            // why is this here
        }
    }

    addPlayer = (player) => {
        if (this.props.game.players.length > 7) {
            alert('Only 8 players may be assigned to this lane.');
            return;
        }
        Game.assignPlayerToTeam(player);
    }

    renderAvailablePlayers = () => {
        return this.props.app.players.filter((player) => {
            return !this.props.game.players.includes(player.id) && player.name.toLowerCase().match(this.state.search.toLowerCase());
        }).map((player, index) => {
            return (
                <li key={index} className="reorder">
                    <i className="icon-add" onClick={() => this.addPlayer(player)}></i>
                    <span>{player.name}</span>
                </li>
            )
        })
    }

    renderSelectedPlayers = () => {
        if (!this.props.game.selectPlayersActiveTeam) {
            return null;
        }
        const players = this.props.game.gameState.filter((team) => team.id === this.props.game.selectPlayersActiveTeam)[0].players;
        return <GameSetupSelectedPlayers players={players} inGame={this.props.game.readyToPlay}/>
    }

    handleSearch = (e) => {
        this.setState({ search: e.target.value });
    }

    loadPlayers = () => {
        this.setState({ loading: true });

        window.setTimeout(() => {
            // Reservation.load(() => this.setState({ loading: false }));
            Request.get(Endpoints.getPlayers(this.props.app.reservationNumber)).then((response) => {
                console.log(response);
                Dispatcher.dispatch({
                    type: Actions.REFRESH_PLAYERS,
                    data: response.data.data
                });
                this.setState({ loading: false });
            })
        }, 500)
    }

    goBack = () => {
        Game.setScoringStyle(null);
        Game.resetGameState();
        Display.togglePlayerSetup(false);
        Display.toggleGameSetup(true);
        Display.toggleGameSetupScoring(true);
    }

    closeAndSendPlayers = () => {
        Display.togglePlayerSetup(false)
        setTimeout( () => {
            Game.sendGameState(this.props.app, this.props.game);
        }, 1500);
    }

    render() {
        return (
            <div className={`modal modal-players underlay ${this.props.display.showPlayerSetup ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <div className="toppers">
                        <div className="cell available">
                            <div className="input-group">
                                <label>Search Players</label>
                                <input type="text" placeholder="Find player by name" value={this.state.search} onChange={this.handleSearch} />
                            </div>
                            <h3>
                                Available Players
                                &nbsp;
                                <Spinner onClick={this.loadPlayers} loading={this.state.loading} />
                            </h3>
                        </div>
                        <div className="cell chosen">

                            <SelectPlayersTeamTabs game={this.props.game} />

                            <h3>Selected Players</h3>
                        </div>
                    </div>
                    <div className="players">
                        <div className="cell">
                            <ul className="player-list">
                                {this.renderAvailablePlayers()}
                            </ul>
                        </div>
                        <div className="cell">
                            <ul className="player-list">
                                {this.renderSelectedPlayers()}
                            </ul>
                        </div>
                    </div>
                    <div className="actions">
                        <div className="cell">
                            {/* <div className="btn btn-trans btn-enter-gameplay" onClick={() => Display.togglePlayerSetup(false)}>Close</div> */}
                            {/* If the game has already been started, show editing buttons */}
                            { this.props.game.readyToPlay &&
                                // <div className="btn btn-gold btn-enter-gameplay" onClick={this.validatePlayers}>Update Players</div>
                                <div className="btn btn-outline btn-enter-gameplay" onClick={() => this.closeAndSendPlayers()}>Close</div>
                            }
                            {/* If the game has not been started, show starting buttons */}
                            { !this.props.game.readyToPlay &&
                                <>
                                <div className="btn btn-outline btn-enter-gameplay" onClick={this.goBack}>Go Back</div>
                                <div className="btn btn-gold btn-enter-gameplay" onClick={this.validatePlayers}>Start Game</div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}