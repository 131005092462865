import React from 'react';
import BucketList from '../lib/BucketList';

export default class BucketListPlayerScores extends React.Component {

    render() {
        return (
            <tr className="player">
                <th>{this.props.name}</th>
                <td className='td-bl' onClick={() => this.props.setPlayerToEditThrows(this.props.playerData.id)}>{this.props.playerData.throwCount}</td>
                <td className='td-bl' onClick={() => this.props.editPlayerScore(0, this.props.playerData)}>{BucketList.getBucketScore(0, this.props.playerData)}</td>
                <td className='td-bl' onClick={() => this.props.editPlayerScore(1, this.props.playerData)}>{BucketList.getBucketScore(1, this.props.playerData)}</td>
                <td className='td-bl' onClick={() => this.props.editPlayerScore(2, this.props.playerData)}>{BucketList.getBucketScore(2, this.props.playerData)}</td>
                <td className='td-bl' onClick={() => this.props.editPlayerScore(3, this.props.playerData)}>{BucketList.getBucketScore(3, this.props.playerData)}</td>
                <td className='td-bl' onClick={() => this.props.editPlayerScore(4, this.props.playerData)}>{BucketList.getBucketScore(4, this.props.playerData)}</td>
                { this.props.blueBalls && 
                    <td className='td-bl' onClick={() => this.props.editPlayerScore(5, this.props.playerData)}>{BucketList.getBucketScore(5, this.props.playerData)}</td>
                }
            </tr>
        )
    }

}


