import React from 'react';
import MagicNumber from '../lib/MagicNumber';

export default class MagicNumberPlayerScores extends React.Component {

    evalCanEditScore = (round, throwCount, playerData) => {
        let currentRound = 0;
        if (throwCount >= 0 && throwCount <= 2) {
            currentRound = 0;
        } else if (throwCount >= 3 && throwCount <= 5) {
            currentRound = 1;
        } else if (throwCount >= 6 && throwCount <= 8) {
            currentRound = 2;
        } else if (throwCount >= 9 && throwCount <= 11) {
            currentRound = 3;
        } else if (throwCount >= 12 && throwCount <= 14) {
            currentRound = 4;
        } else {
            currentRound = 5;
        }
        // else if ((throwCount >= 15 && throwCount <= 17) && blueBalls) {
        //     currentRound = 6;
        // }

        if (currentRound === round) {
            this.props.allowClearScore(round, this.props.playerData)
        } else {
            if (round === 0 & throwCount >= 3) {
                this.props.setPlayerRound(0, this.props.playerData)
            } else if (round === 1 & throwCount >= 6) {
                this.props.setPlayerRound(1, this.props.playerData)
            } else if (round === 2 & throwCount >= 9) {
                this.props.setPlayerRound(2, this.props.playerData)
            } else if (round === 3 & throwCount >= 12) {
                this.props.setPlayerRound(3, this.props.playerData)
            } else if (round === 4 & throwCount >= 15) {
                this.props.setPlayerRound(4, this.props.playerData)
            } else if (round === 5 & throwCount >= 18) {
                this.props.setPlayerRound(5, this.props.playerData)
            }
        }
    }

    render() {
        return (
            <tr className="player">
                <th>{this.props.name}</th>
                <td className='td-mn' onClick={() => this.evalCanEditScore(0, this.props.playerData.throwCount, this.props.playerData)}>{MagicNumber.getMagicScoreText(0, this.props.playerData)}</td>
                <td className='td-mn' onClick={() => this.evalCanEditScore(1, this.props.playerData.throwCount, this.props.playerData)}>{MagicNumber.getMagicScoreText(1, this.props.playerData)}</td>
                <td className='td-mn' onClick={() => this.evalCanEditScore(2, this.props.playerData.throwCount, this.props.playerData)}>{MagicNumber.getMagicScoreText(2, this.props.playerData)}</td>
                <td className='td-mn' onClick={() => this.evalCanEditScore(3, this.props.playerData.throwCount, this.props.playerData)}>{MagicNumber.getMagicScoreText(3, this.props.playerData)}</td>
                <td className='td-mn' onClick={() => this.evalCanEditScore(4, this.props.playerData.throwCount, this.props.playerData)}>{MagicNumber.getMagicScoreText(4, this.props.playerData)}</td>
                { this.props.blueBalls && 
                    <td className='td-mn' onClick={() => this.evalCanEditScore(5, this.props.playerData.throwCount, this.props.playerData)}>{MagicNumber.getMagicScoreText(5, this.props.playerData)}</td>
                }
                <td className='td-mn final gray-bg'>{MagicNumber.getTotalScoreForPlayer(this.props.playerData.magicNumber)}</td>
            </tr>
        )
    }

}


