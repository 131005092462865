import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';
import EmailScores from './EmailScores';
import MagicNumber from '../lib/MagicNumber';
import ModalMagicNumber from './ModalMagicNumber';
import MagicNumberPlayerScores from './MagicNumberPlayerScores';

export default class ScoreboardMagicNumber extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            playerId: null,
            playerData: null,
            round: null,
            score: null,
            showScoreModal: null,
            throwNumber: null,
            editingScore: null,
            clearScore: null
        }
    }

    closeScoreModal = () => {
        this.setState({ showScoreModal: false });
    }

    renderTeams = (gameState) => {
        if (gameState.length > 0) {
            return gameState.map((team, index) => {
                return (
                    <tbody key={index}>
                        {this.props.game.scoringStyle !== 'Individual' &&
                            <tr className="team">
                                <th>{this.props.game.scoringStyle === 'Lane Total' ? `Lane ${this.props.app.laneNumber}` : team.name}</th>
                                <td>{MagicNumber.getScoreForTeam(0, team)}</td>
                                <td>{MagicNumber.getScoreForTeam(1, team)}</td>
                                <td>{MagicNumber.getScoreForTeam(2, team)}</td>
                                <td>{MagicNumber.getScoreForTeam(3, team)}</td>
                                <td>{MagicNumber.getScoreForTeam(4, team)}</td>
                                { this.props.game.magicNumberBlueBalls && 
                                    <td>{MagicNumber.getScoreForTeam(5, team)}</td>
                                }
                                <td className="final">{MagicNumber.getTotalScoreForTeam(team)}</td>
                            </tr>
                        }
                        {team && this.renderPlayerScores(team.players)}
                    </tbody>
                )
            });
        }
    }

    renderPlayerScores = (gameState) => {
        return gameState.map((player, index) => {
            return (
                <MagicNumberPlayerScores
                    setPlayerRound={this.setPlayerRoundToEdit}
                    allowClearScore={this.allowClearScore}
                    name={player.name}
                    blueBalls={this.props.game.magicNumberBlueBalls}
                    playerData = {player}
                    id={player.id}
                    key={index} />
            );
        });
    }

    allowClearScore = (roundIndex, playerData, editingScore) => {
        this.setState({round: roundIndex, playerData: playerData, playerId: playerData.id, editingScore: editingScore, clearScore: true, showScoreModal: true});
    }

    setPlayerRoundToEdit = (roundIndex, playerData, editingScore) => {
        this.setState({round: roundIndex, playerData: playerData, playerId: playerData.id, editingScore: editingScore, clearScore: false, showScoreModal: true});
    }


    editTempPlayerScore = (pts) => {
        this.closeScoreModal();
        if (pts < 0) {
            // Clear the score
            MagicNumber.editTempScoreForPlayer(this.state.playerData, this.state.round, pts);
        } else {
            MagicNumber.editTempScoreForPlayer(this.state.playerData, this.state.round, pts);
        }
    }

    updateScoresAndClose = () => {
        Game.setGameStateToModified();
        MagicNumber.updatePlayerMagicScoreAfterEdit();
        Display.toggleLeagueScoreboard(false);

        setTimeout( () => {
            Game.sendGameState(this.props.app, this.props.game);
        }, 1500);
    }

    render() {
        let stateType;
        if (!this.props.game.modifyGameState) {
            stateType = this.props.game.gameState;
        } else {
            stateType = this.props.game.modifyGameState
        }
        return (
            <>
                <div className={`scoreboard scoreboard-league ${this.props.display.showLeagueScoreboard ? "active" : "inactive"}`}>
                    <div id='score-grid' className="score-grid">
                        <table>
                            <thead>
                                <tr>
                                    <th></th><th>1</th><th>2</th><th>3</th><th>4</th><th>Bullseye</th>{ this.props.game.magicNumberBlueBalls && <th>Blue Ball</th> }<th>Total</th>
                                </tr>
                            </thead>
                            {this.renderTeams(stateType)}
                        </table>
                    </div>
                    { this.props.game.gameEnded
                        ? 
                            <EmailScores gameId={this.props.game.gameId} game={this.props.game.game} />
                        :
                            <div className="actions" style={{position: "absolute", left: "24%", bottom: "0"}}>
                                <div className="btn btn-outline btn-cancel" onClick={() => Display.toggleLeagueScoreboard(false)}>Cancel</div>
                                <div className="btn btn-gold btn-save" onClick={this.updateScoresAndClose}>Save &amp; Return</div>
                            </div>
                    }
                    {/* <div className="actions">
                        <div className="btn btn-outline btn-cancel" onClick={() => Display.toggleLeagueScoreboard(false)}>Cancel</div>
                        <div className="btn btn-gold btn-save" onClick={this.updateScoresAndClose}>Save &amp; Return</div>
                    </div> */}
                </div>
                <ModalMagicNumber
                    showModal={this.state.showScoreModal}
                    closeModal={this.closeScoreModal}
                    clearScore={this.state.clearScore}
                    editPlayerScore={this.editTempPlayerScore} />
            </>
        )
    }
}