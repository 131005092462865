import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';

export default class ModalTargetMenu extends React.Component {

    closeModal = () => {
        this.props.toggleMenuModal(false);
    }

    showLeagueScoreboard = () => {
        Game.setGameStateToModify(this.props.gameState);
        this.closeModal();
        Display.toggleLeagueScoreboard(true);
    }

    showPlayerSetup = () => {
        this.closeModal();
        Display.togglePlayerSetup(true);
    }

    showInstructions = () => {
        this.closeModal();
        Display.toggleInstructions(true);
    }

    endGame = () => {
        this.closeModal();
        Display.toggleEndGameModal(true);
    }

    render() {
        return (
            <div className={`modal modal-end underlay ${this.props.showMenu ? "active" : "inactive"}`}>
                <div className="modal-body" style={{width: "3.5rem"}}>
                    <h2 style={{marginBottom: '25px'}}>Game Menu</h2>

                    <div className="btn btn-gold" style={{width: "100%"}} onClick={this.showLeagueScoreboard}>Edit Scores</div>
                    <div className="btn btn-gold" style={{width: "100%", marginTop: '20px'}} onClick={this.showInstructions}>How to Play</div>
                    <div className="btn btn-gold" style={{width: "100%", marginTop: '20px'}} onClick={this.showPlayerSetup}>Edit Players</div>
                    <div className="btn btn-gold" style={{width: "100%", marginTop: '20px'}} onClick={this.endGame}>End Game</div>

                    <div className="modal-actions" style={{paddingBottom: '20px'}}>
                        <div className="btn btn-outline btn-close" onClick={this.closeModal}>Close</div>
                    </div>
                </div>
            </div>
        )
    }
}