import Actions from '../lib/Actions';
import Display from '../lib/Display';
import Dispatcher from '../dispatchers/Dispatcher';

export default class MagicNumber {

    static toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });
    }

    static evalHitAction = (currentThrower, currentTeam, scoringStyle, blueBalls, pts, gameState) => {
        let round = 0;
        if (currentThrower.throwCount >= 0 && currentThrower.throwCount <= 2) {
            round = 1;
            let magicIndex = (currentThrower.throwCount % 3);
            round === pts ? this.logScore(currentThrower, round-1, magicIndex, 1, gameState, scoringStyle, blueBalls) : this.logScore(currentThrower, round-1, magicIndex, 0, gameState, scoringStyle, blueBalls);
        } else if (currentThrower.throwCount >= 3 && currentThrower.throwCount <= 5) {
            round = 2;
            let magicIndex = (currentThrower.throwCount % 3);
            round === pts ? this.logScore(currentThrower, round-1, magicIndex, 1, gameState, scoringStyle, blueBalls) : this.logScore(currentThrower, round-1, magicIndex, 0, gameState, scoringStyle, blueBalls);
        } else if (currentThrower.throwCount >= 6 && currentThrower.throwCount <= 8) {
            round = 3;
            let magicIndex = (currentThrower.throwCount % 3);
            round === pts ? this.logScore(currentThrower, round-1, magicIndex, 1, gameState, scoringStyle, blueBalls) : this.logScore(currentThrower, round-1, magicIndex, 0, gameState, scoringStyle, blueBalls);
        } else if (currentThrower.throwCount >= 9 && currentThrower.throwCount <= 11) {
            round = 4;
            let magicIndex = (currentThrower.throwCount % 3);
            round === pts ? this.logScore(currentThrower, round-1, magicIndex, 1, gameState, scoringStyle, blueBalls) : this.logScore(currentThrower, round-1, magicIndex, 0, gameState, scoringStyle, blueBalls);
        } else if (currentThrower.throwCount >= 12 && currentThrower.throwCount <= 14) {
            round = 5;
            let magicIndex = (currentThrower.throwCount % 3);
            // Bullseye are 6 points so we have to check this directly
            pts === 6 ? this.logScore(currentThrower, round-1, magicIndex, 1, gameState, scoringStyle, blueBalls) : this.logScore(currentThrower, round-1, magicIndex, 0, gameState, scoringStyle, blueBalls);
        } else if ((currentThrower.throwCount >= 15 && currentThrower.throwCount <= 17) && blueBalls) {
            round = 6;
            let magicIndex = (currentThrower.throwCount % 3);
            // Blue hits are sent over as 'blue'
            pts === 'blue' ? this.logScore(currentThrower, round-1, magicIndex, 1, gameState, scoringStyle, blueBalls) : this.logScore(currentThrower, round-1, magicIndex, 0, gameState, scoringStyle, blueBalls);
        }

        if (round === 5 && pts === 6) {
            // alert(pts);
            // Display.toggleRingClosedModal('Wrong ring!');
        } else if (round === 6 && pts === 'blue') {
            // Display.toggleRingClosedModal('Wrong ring!');
        } else if (round !== pts) {
            Display.toggleRingClosedModal('Wrong ring!');
        }
    }

    static logScore(playerData, round, index, pts, gameState, scoringStyle, includeBlueBalls) {
        let hitData = { playerId: playerData.id, round: round, index: index, score: pts };
        // console.log(hitData);
        this.toggle(Actions.LOG_PLAYER_MAGIC_SCORE, hitData);

        // Update gamestate to how it will be after the dispatch
        gameState = gameState.map((team) => {
            return {
                ...team, 
                players: team.players.map((player) => {
                    if (player.id === playerData.id) {
                        let playerScore = player.magicNumber;
                        playerScore[round][index] = pts;
                        return {
                            ...player,
                            magicNumber: playerScore,
                            throwCount: player.throwCount + 1
                        }
                    } else {
                        return player;
                    }
                })
            }
        })
        let gameOver = true;
        let winnerName = [];
        let winnerScore = 0;

        if (scoringStyle === 'Individual') {
            // alert('individ');
            gameState.forEach((team) => {
                team.players.forEach((player) => {
                    if (this.calculateRing(player.throwCount, includeBlueBalls) === 'Complete') {
                        // console.log('player complete');
                        let playerScore = this.getTotalScoreForPlayer(player.magicNumber);
                        // console.log(player.name + " has " + playerScore);
                        if (playerScore === winnerScore) {
                            // console.log ('tied score');
                            winnerName.push(player.name);
                        } else if (playerScore > winnerScore) {
                            // console.log('new high score');
                            winnerScore = playerScore;
                            winnerName = [player.name];
                        }
                    } else {
                        // console.log(player.name + " doesn't have 10 throws");
                        gameOver = false;
                    }
                })
            });            
        } else if (scoringStyle === 'Team Total') {
            let allThrows = true;
            // alert('team');

            gameState.forEach((team) => {
                team.players.forEach((player) => {
                    if (this.calculateRing(player.throwCount, includeBlueBalls) !== 'Complete') {
                        allThrows = false;
                        gameOver = false;
                    }
                });
            });
            
            if (allThrows) {
                gameState.forEach((team) => {
                    let teamScore = this.getTotalScoreForTeam(team);
                    if (teamScore === winnerScore) {
                        // console.log ('tied score');
                        winnerName.push(team.name);
                    } else if (teamScore > winnerScore) {
                        // console.log('new high score');
                        winnerName = [team.name];
                        winnerScore = teamScore;
                    } else {
                        // console.log('not on board')
                    }
                });  
            }
        } else {
            let allThrows = true;

            gameState.forEach((team) => {
                team.players.forEach((player) => {
                    if (this.calculateRing(player.throwCount, includeBlueBalls) !== 'Complete') {
                        allThrows = false;
                        gameOver = false;
                    }
                });
            });
            
            if (allThrows) {
                gameState.forEach((team) => {
                    winnerScore = this.getTotalScoreForTeam(team);
                })
            }
        }

        if (gameOver) {
            let winnerData = { winnerName: winnerName, winnerScore: winnerScore, game: 'Magic Number', scoringStyle: scoringStyle };
            this.toggle(Actions.WINNER_MESSAGE, winnerData);
        }
    }

    static calculateMagicScoreText = (index, multiplyer, data) => {
        let numHits = data.magicNumber[index].reduce((a, b) => a + b, 0)
        if (numHits === 0) {
            return '0';
        } else if (numHits === 1) {
            return `${1 * multiplyer} (1)`
        } else if (numHits === 2) {
            return `${2 * multiplyer} (2)`
        } else if (numHits === 3) {
            return `${3 * multiplyer} (3)`
        } else {
            return '';
        }
    }

    static getMagicScoreText = (index, data) => {
        switch (index) {
            case 0:
                return this.calculateMagicScoreText(index, 1, data);
            case 1:
                return this.calculateMagicScoreText(index, 2, data);
            case 2:
                return this.calculateMagicScoreText(index, 3, data);
            case 3:
                return this.calculateMagicScoreText(index, 4, data);
            case 4:
                return this.calculateMagicScoreText(index, 6, data);
            case 5:
                return this.calculateMagicScoreText(index, 10, data);
            default:
                return ''
        }
    }

    static calculateMagicScore = (index, multiplyer, data) => {
        let numHits = data.magicNumber[index].reduce((a, b) => a + b, 0)
        if (numHits === 0) {
            return 0;
        } else if (numHits === 1) {
            return 1 * multiplyer
        } else if (numHits === 2) {
            return 2 * multiplyer
        } else if (numHits === 3) {
            return 3 * multiplyer
        } else {
            return 0;
        }
    }

    static getMagicScore = (index, data) => {
        switch (index) {
            case 0:
                return this.calculateMagicScore(index, 1, data);
            case 1:
                return this.calculateMagicScore(index, 2, data);
            case 2:
                return this.calculateMagicScore(index, 3, data);
            case 3:
                return this.calculateMagicScore(index, 4, data);
            case 4:
                return this.calculateMagicScore(index, 6, data);
            case 5:
                return this.calculateMagicScore(index, 10, data);
            default:
                return ''
        }
    }

    static getTotalScoreForPlayer = (playerData) => {
        let score = 0;
        for (let i = 0; i < playerData.length; i++) {
            if (i === 0) {
                score += (1 * playerData[i].reduce((a, b) => a + b, 0))
            } else if (i === 1) {
                score += (2 * playerData[i].reduce((a, b) => a + b, 0))
            } else if (i === 2) {
                score += (3 * playerData[i].reduce((a, b) => a + b, 0))
            } else if (i === 3) {
                score += (4 * playerData[i].reduce((a, b) => a + b, 0))
            } else if (i === 4) {
                score += (6 * playerData[i].reduce((a, b) => a + b, 0))
            } else if (i === 5) {
                score += (10 * playerData[i].reduce((a, b) => a + b, 0))
            }
        }
        return score;
    }

    static getScoreForTeam = (index, teamData) => {
        let teamScore = 0;
        if (!teamData) {
            return 0;
        }
        teamData.players.forEach((player, i, players) => 
            teamScore += this.getMagicScore(index, player)
        )
        return teamScore;
    }

    static getTotalScoreForTeam = (teamData) => {
        let teamScore = 0;
        if (!teamData) {
            return 0;
        }
        teamData.players.forEach((player, i, players) => 
            teamScore += this.getTotalScoreForPlayer(player.magicNumber)
        )
        return teamScore;
    }

    static editTempScoreForPlayer = (playerData, round, pts) => {
        var newArray = playerData.magicNumber.map(function(arr) {
            return arr.slice();
        });
        let hitData = { playerId: playerData.id, round: round, throwCount: playerData.throwCount, magicNumber: newArray, points: pts }
        this.toggle(Actions.TEMP_EDIT_PLAYER_MAGIC_SCORE, hitData);
    }

    static updatePlayerMagicScoreAfterEdit = () => {
        this.toggle(Actions.UPDATE_PLAYER_MAGIC_SCORE_AFTER_EDIT);
    }

    static getPlayerDisplayScore = (playerData, includeBlueBalls) => {
        let ring = this.calculateRing(playerData.throwCount, includeBlueBalls);
        let totalPoints = this.getTotalScoreForPlayer(playerData.magicNumber);
        return totalPoints + " pts (Ring: " + ring + ")";
    }

    static getTeamDisplayScore = (teamData, includeBlueBalls) => {
        return " - " + this.getTotalScoreForTeam(teamData);
    }

    static calculateRing = (throwCount, includeBlueBalls) => {
        if (throwCount >= 0 && throwCount <= 2) {
            return 1;
        } else if (throwCount >= 3 && throwCount <= 5) {
            return 2;
        } else if (throwCount >= 6 && throwCount <= 8) {
            return 3;
        } else if (throwCount >= 9 && throwCount <= 11) {
            return 4;
        } else if (throwCount >= 12 && throwCount <= 14) {
            return 'Bullseye'
        } else if ((throwCount >= 15 && throwCount <= 17) && includeBlueBalls) {
            return 'Blue Ball'
        } else {
            return 'Complete';
        }
    }

    static playerGameStatus = (playerData, includeBlueBalls) => {
        let completed = false;
        if (includeBlueBalls && playerData.throwCount >= 18) {
            completed = true;
        } else if (!includeBlueBalls && playerData.throwCount >= 15) {
            completed = true;
        }
        return completed;
    }
}