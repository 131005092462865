import React from 'react'

const Spinner = (props) => {
    return (
        <i
            onClick={props.onClick}
            className={`fa fa-redo ${props.loading ? 'spinning' : ''}`} />
    );
}

export default Spinner