import React from 'react';
import Lane from '../lib/Lane';
import Actions from '../lib/Actions';
import Display from '../lib/Display';
import Storage from "../lib/Storage";
import Endpoints from '../lib/Endpoints';
import Request from '../lib/Request';
import Reservation from '../lib/Reservation';
import Dispatcher from '../dispatchers/Dispatcher';
import Game from '../lib/Game';

export default class Home extends React.Component {

    toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    /**
     * Check for cancellations by seeing if we have a current reservationNumber
     * and the response from the server is "not found"
     * @param {*} response response from booking app
     */
    checkForCancellation = (response) => {
        if (this.props.app.reservationNumber && response.data.message && response.data.message === "No reservation") {
            window.location.reload(false);
        }
    };

    /**
     * Update welcome message
     * @param {*} response response from booking app
     */
    checkWelcomeMessage = (response) => {
        if (response.data.data.welcomeMessage && response.data.data.welcomeMessage !== this.props.welcomeMessage) {
            this.toggle(Actions.WELCOME_MESSAGE, response.data.data.welcomeMessage);
        }
    };

    /**
     * Check if the reservation has switched lanes.
     * If our lane is no longer in the list of lanes, reload the app
     * @param {*} response response from booking app
     * @param {*} laneNumber current lane number
     */
    checkLaneChange = (response, laneNumber) => {
        if (response.data.data.laneNumbers && response.data.data.laneNumbers.length > 0) {
            if (!response.data.data.laneNumbers.includes(laneNumber)) {
                window.location.reload(false);
            }
        }
    };

    fetchReservationLoop = () => {
        let endTimestamp, prevEndTimestamp, endTime, warningTime, laneNumber = null;
        let warned, expired = false;
        const second = 1000;
        const minute = 60 * second;
        setInterval(() => {
            laneNumber = parseInt(Storage.getLaneNumber());

            if (!this.props.app.reservationNumber) {
                Reservation.load(this.props.app.locationId, this.props.app.laneNumber);
            } else {
                // Process the end time and manage alerts
                Request.get(Endpoints.reservationStatus(this.props.app.reservationNumber)).then((response) => {

                    this.checkWelcomeMessage(response);

                    this.checkLaneChange(response, laneNumber);

                    if(response.data.data.estimatedEnd !== null && response.data.data.estimatedEnd !== undefined) {
                        // console.log('Found end time');
                        // console.log("End time: ", response.data.data.estimatedEnd);
                        endTimestamp = response.data.data.estimatedEnd

                        // The ending time has changed so recalculate the new warning time
                        // Reset the warned and expired flags since the time could have been extended
                        if (endTimestamp !== prevEndTimestamp) {
                            // console.log('the end time has been updated');
                            prevEndTimestamp = endTimestamp;
                            endTime = new Date(endTimestamp);
                            // console.log('end time: ', endTime);
                            warningTime = new Date( endTime.getTime() - (15 * minute) );
                            // console.log('warning time: ', warningTime);
                            warned = false;
                            expired = false;
                            this.toggle(Actions.MODAL_TIME_WARNING, false);
                            this.toggle(Actions.SHOWN_TIME_WARNING, false);
                            this.toggle(Actions.RESERVATION_TIMEOUT, false);
                            Lane.agreedTimeWarning(null);
                            Lane.extendReservation(null);
                            setTimeout( () => {
                                Game.sendGameState(this.props.app, this.props.game);
                            }, 1500);
                        }

                        if (this.props.app.groupInLane) {
                            if (new Date() > warningTime && !warned) {
                                this.toggle(Actions.MODAL_TIME_WARNING, true);
                                this.toggle(Actions.SHOWN_TIME_WARNING, true);
                                warned = true;
                                setTimeout( () => {
                                    Game.sendGameState(this.props.app, this.props.game);
                                }, 1500);
                            }

                            if (new Date() >= endTime && !expired) {
                                // console.log("Expired: ", expired);
                                this.toggle(Actions.RESERVATION_TIMEOUT, true);
                                expired = true;
                                setTimeout( () => {
                                    Game.sendGameState(this.props.app, this.props.game);
                                }, 1500);
                            }
                        }
                    }
                }).catch(err => {
                    // it would be better if this didn't 404 so we can check the 
                    // response in a cleaner way.
                    this.checkForCancellation(err.response);
                });
            }
        }, 15000);
    }

    sendGameStateLoop = () => {
        setInterval(() => {
            // console.log('game state loop');
            // this is a workaround to only start sending gamestate up when the group has been marked in lane.
            // it will let the TV app correctly poll for if the reservation has ended before the next one
            // starts sending up data
            if (this.props.app.groupInLane) {
                Request.post(Endpoints.sendGameState(this.props.app.reservationNumber, this.props.app.laneNumber), {
                    json_block: { 
                                    "game": this.props.game.game, "readyToPlay": this.props.game.readyToPlay, "gameState": this.props.game.gameState,
                                    "scoringStyle": this.props.game.scoringStyle, "currentThrower":  this.props.game.currentThrower,
                                    "currentTeam": this.props.game.currentTeam, "bucketListBlueBalls": this.props.game.bucketListBlueBalls, 
                                    "bustOrStay": this.props.game.bustOrStay, "magicNumberBlueBalls": this.props.game.magicNumberBlueBalls,
                                    "showTimeWarning": this.props.app.shownTimeWarning, "agreedTimeWarning": this.props.app.agreedTimeWarning,
                                    "reservationTimeout": this.props.app.reservationTimeout, "reservationExtended": this.props.app.reservationExtended,
                                    "throwData": { "gameState": this.props.game.eventGameState, "currentThrower": this.props.game.eventCurrentThrower, "currentTeam": this.props.game.eventCurrentTeam, "points": this.props.game.eventPoints, "timestamp": this.props.game.eventTimestamp }
                                }
                });
            }
        }, 1500);
    }

    componentDidMount() {
        this.fetchReservationLoop();
        // this.endTimeLoop();
        // this.sendGameStateLoop();
    }

    formatReservationName = () => {
        if (this.props.app.welcomeMessage) {
            return this.props.app.welcomeMessage;
        } else {
            let lastName = this.props.app.reservationName.split(' ').pop();
            return "Welcome " + lastName + " Party!";
        }
    }

    showHome = () => {
        if (this.props.display.showBrowseGames || this.props.app.groupInLane) {
            return false;
        } else {
            return true
        }
    }

    render() {
        return (
            <div id="home" className={`${this.showHome() ? "" : "inactive"}`}>
                <div id="lane">{this.props.app.reservationName ? this.formatReservationName() : `Lane ${this.props.app.laneNumber}`}</div>
                <div id="browse-games-btn" className="btn btn-gold" 
                    onClick={() => {
                        Lane.getGameRules();
                        Display.toggleBrowseGames(true);
                    }}>
                    Browse Games
                </div>
            </div>
        )
    }

}