import React from 'react';
import Display from '../lib/Display';

export default class Modal18Keypad extends React.Component {

    closeModal = () => {
        Display.toggleModalKeypad18(false);
    }

    render() {
        return (
            <div className={`modal modal-score-1-8 underlay ${this.props.showModalKeypad18 ? "active" : ""}`}>
                <div className="modal-body">
                    <div className="keypad">
                        <div className="btn btn-gold" onClick={() => this.props.editPlayerScore(1)}>1</div>
                        <div className="btn btn-gold" onClick={() => this.props.editPlayerScore(2)}>2</div>
                        <div className="btn btn-gold" onClick={() => this.props.editPlayerScore(3)}>3</div>
                        <div className="btn btn-gold" onClick={() => this.props.editPlayerScore(4)}>4</div>
                    </div>
                    <div className="actions">
                        <div className="btn btn-gold btn-block btn-enter" style={{marginBottom: "15px"}} onClick={() => this.props.editPlayerScore(6)}>Bullseye</div>
                        <div className="btn btn-gold btn-block btn-enter" style={{marginBottom: "15px"}} onClick={() => this.props.editPlayerScore('blue')}>Blue Ball</div>
                        <div className="btn btn-gold btn-block btn-enter" style={{marginBottom: "15px"}} onClick={() => this.props.editPlayerScore(0)}>Miss</div>

                        {/* Only show the button to remove a score if the current score can be removed */}
                        { this.props.canRemoveScore &&
                            <div className="btn btn-gold btn-block btn-enter" style={{marginBottom: "15px"}} onClick={() => this.props.removeScoreAction()}>Clear Score</div>
                        }

                        <div className="btn btn-blank btn-block btn-close" onClick={() => this.props.editPlayerScore(-1)}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}