import React from 'react';
import Display from '../lib/Display';

export default class ModalInstructions extends React.Component {
    
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    showGameInstructions = () => {
        // If there are no rules found or no game set (either playing or to get rules for),
        // return nothing. Else return the rules for either the current game or the specifically
        // requested game
        if (!this.props.rules) {
            return '<p>No game rules found.</p>';
        } else if (this.props.currentGame) {
            return this.findInstructions(this.props.currentGame);
        } else if (this.props.instructionGame) {
            return this.findInstructions(this.props.instructionGame);
        } else {
            return '<p>No game rules found.</p>'
        }
    }

    findInstructions = (game) => {
        let rules = "<p>No game rules found.</p>";
        this.props.rules.forEach((gameRule) => {
            if (gameRule.name === game || (gameRule.name === 'First To 21' && game === 'First to 21')) {
                // Simply returning here isn't working
                rules = gameRule.description;
            }
        });
        return rules;
    }

    closeModal = () => {
        this.myRef.current.scrollTop = 0;
        Display.toggleInstructions(false)
    }

    render() {
        return (
            <div className={`modal modal-instructions underlay ${this.props.showInstructions ? "active" : ""}`}>
                <div className="modal-body">
                    <div ref={this.myRef} className="modal-content">
                        <div dangerouslySetInnerHTML={{ __html: this.showGameInstructions() }} />
                    </div>
                    <div className="modal-actions">
                        {/* <div className="btn btn-outline btn-close" onClick={() => Display.toggleInstructions(false)}>Close</div> */}
                        <div className="btn btn-outline btn-close" onClick={this.closeModal}>Close</div>
                    </div>
                </div>
            </div>
        )
    }
}