import React from 'react';
import { Container } from 'flux/utils';
import '../scss/styles.scss';
import AppStore from '../stores/AppStore';
import GameStore from '../stores/GameStore';
import DisplayStore from '../stores/DisplayStore';
// import '../scss/App.scss';

import Lane from '../lib/Lane';
import Game from '../lib/Game';
import Reservation from '../lib/Reservation'
import Display from '../lib/Display';
import Target from '../components/Target';
import Brand from '../components/Brand';
import Home from '../components/Home';
import BrowseGames from '../components/BrowseGames';
import AdminChooseActions from '../components/AdminChooseActions';
import GameSetup from '../components/GameSetup';
import GameSetupSelectPlayers from '../components/GameSetupSelectPlayers';
import Scoreboard21 from '../components/Scoreboard21';
import ScoreboardLeague from '../components/ScoreboardLeague';
import ScoreboardBucketList from '../components/ScoreboardBucketList';
import ScoreboardMagicNumber from '../components/ScoreboardMagicNumber';
import GamePlayers from '../components/GamePlayers';
import ModalAdmin from '../components/ModalAdmin';
import ModalInstructions from '../components/ModalInstructions';
import ModalEndGame from '../components/ModalEndGame';
import ModalKeypad from '../components/ModalKeypad';
import ModalTimeWarning from '../components/ModalTimeWarning';
import ModalReservationTimeout from '../components/ModalReservationTimeout';

function getStores() {
    return [
        AppStore,
        GameStore,
        DisplayStore
    ];
}

function getState() {
    const state = {
        app: AppStore.getState(),
        game: GameStore.getState(),
        display: DisplayStore.getState()
    };

    return state;
}

function App(props) {

    // We want to make sure we have time to fetch the branded images
    // before rendering anything so there isn't a flicker while the
    // logos change
    if (!props.app.initialFetch && props.app.locationId != null && props.app.laneNumber != null) {
        if (!props.app.reservationNumber) {
            Reservation.load(props.app.locationId, props.app.laneNumber);
        }
        return <div />
    }

    return (
        <div id="app" className={`${props.game.readyToPlay ? "game-target" : ""} ${props.display.showLeagueScoreboard ? "view-scores" : ""}`}>
            <div id="wood"></div>
            <Target {...props } />
            <div id="overlay"></div>
            <Brand logo={props.app.appLogo} reservation={props.app.reservationNumber} reservationStarted={props.app.reservationStarted} />
            <div id="interface">
                <Home {...props} />
                <BrowseGames {...props} />
                <AdminChooseActions {...props} />
                <GameSetup {...props} />
            </div>

            {/* <GameSetupScoring {...props} />
            <GameSetupTeamNames {...props} /> */}

            <GameSetupSelectPlayers {...props} />
            { props.game.game === 'League Style' && <ScoreboardLeague {...props} /> }
            { props.game.game === 'First to 21' && <Scoreboard21 {...props} /> }
            { props.game.game === 'Bucket List' && <ScoreboardBucketList {...props} /> }
            { props.game.game === 'Magic Number' && <ScoreboardMagicNumber {...props} /> }
            <GamePlayers {...props} />
            {/* <GameSetup {...props} /> */}
          
            <ModalInstructions 
                rules={props.app.gameRules}
                currentGame={props.app.currentGame}
                instructionGame={props.app.instructionGame}
                showInstructions={props.display.showInstructions} />

            <ModalEndGame
                game={props.game}
                app={props.app}
                reservationNumber={props.app.reservationNumber}
                laneNumber={props.app.laneNumber}
                showEndGameModal={props.display.showEndGameModal}
                gameState={props.game.gameState}
                gameEnded={props.game.gameEnded}
                firstPlayer={props.game && props.game.gameState ? Game.getInitialThrower(props.game.gameState) : null} />

            <ModalAdmin
                reservationNumber={props.app.reservationNumber}
                groupInLane={props.app.groupInLane}
                showAdminModal={props.display.showAdminModal}
                locationId={props.app.locationId}
                laneNumber={props.app.laneNumber} />
                
            <ModalKeypad {...props} />

            <ModalTimeWarning showModal={props.display.showModalTimeWarning} game={props.game} app={props.app} />

            <ModalReservationTimeout showModal={props.display.showReservationTimeout} />

            <div id="axearcade">
                <img src="images/axe-arcade.svg" alt="logo-svg" 
                    onClick={() =>  {
                        Lane.toggleAccessingAdmin(true);
                        Display.toggleModalKeypad(true);
                    }}
                />
            </div>

         </div>
    );
}

export default Container.createFunctional(App, getStores, getState);
