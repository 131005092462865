import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';
import Modal18Keypad from './Modal18Keypad';
import LeagueStyle from '../lib/LeagueStyle';
import EmailScores from './EmailScores';
import LeaguePlayerScores from './LeaguePlayerScores';

export default class ScoreboardLeague extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            playerId: null,
            score: null,
            throwNumber: null,
            editingScore: null,
            canRemoveScore: null,
        }
    }

    renderTeams = (gameState) => {
        if (gameState.length > 0) {
            return gameState.map((team, index) => {
                return (
                    <tbody key={index}>
                        {this.props.game.scoringStyle !== 'Individual' &&
                            <tr className="team">
                                <th>{this.props.game.scoringStyle === 'Lane Total' ? `Lane ${this.props.app.laneNumber}` : team.name}</th>
                                <td>{LeagueStyle.getThrowScoreForTeam(0, team)}</td>
                                <td>{LeagueStyle.getThrowScoreForTeam(1, team)}</td>
                                <td>{LeagueStyle.getThrowScoreForTeam(2, team)}</td>
                                <td>{LeagueStyle.getThrowScoreForTeam(3, team)}</td>
                                <td className="midway">{LeagueStyle.getThrowScoreForTeam(4, team)}</td>
                                <td>{LeagueStyle.getThrowScoreForTeam(5, team)}</td>
                                <td>{LeagueStyle.getThrowScoreForTeam(6, team)}</td>
                                <td>{LeagueStyle.getThrowScoreForTeam(7, team)}</td>
                                <td>{LeagueStyle.getThrowScoreForTeam(8, team)}</td>
                                <td>{LeagueStyle.getThrowScoreForTeam(9, team)}</td>
                                <td className="final">{LeagueStyle.getTotalScoreForTeam(team)}</td>
                            </tr>
                        }
                        {team && this.renderPlayerScores(team.players)}
                    </tbody>
                )
            });
        }
    }

    renderPlayerScores = (gameState) => {
        return gameState.map((player, index) => {
            return (
                <LeaguePlayerScores
                    setPlayerScore={this.setPlayerScoreToEdit}
                    playerData = {player}
                    name={player.name}
                    id={player.id}
                    key={index} />
            );
        });
    }

    editPlayerScore = (score) => {
        // If the score is >= 0, we know it's a real score.
        if (score === 'blue' || score >= 0) {
            this.setState({score: score}, () => {
                if (this.state.editingScore) {
                    Game.editTempThrowForPlayer(this.state.playerId, this.state.score, this.state.throwNumber);
                } else {
                    Game.createTempHitForPlayer(this.state.playerId, this.state.score);
                }
            });
        }
        Display.toggleModalKeypad18(false);
    }

    editPlayerScore = (score) => {
        // If the score is >= 0, we know it's a real score.
        if (score === 'blue' || score >= 0) {
            this.setState({score: score}, () => {
                if (this.state.editingScore) {
                    Game.editTempThrowForPlayer(this.state.playerId, this.state.score, this.state.throwNumber);
                } else {
                    Game.createTempHitForPlayer(this.state.playerId, this.state.score);
                }
            });
        }
        Display.toggleModalKeypad18(false);
    }

    setPlayerScoreToEdit = (scoreIndex, id, editingScore, canRemoveScore=false) => {
        this.setState({throwNumber: scoreIndex, playerId: id, editingScore: editingScore, canRemoveScore: canRemoveScore});
        Display.toggleModalKeypad18(true);
    }

    removePlayerScore = () => {
        Game.tempRemovePlayerScore(this.state.playerId)
        Display.toggleModalKeypad18(false);
    }

    updateScoresAndClose = () => {
        Game.setGameStateToModified();
        Game.updatePlayerThrowCountAfterEdit();
        Display.toggleLeagueScoreboard(false);

        setTimeout( () => {
            Game.sendGameState(this.props.app, this.props.game);
    }, 1500);
    }

    render() {
        let stateType;
        if (!this.props.game.modifyGameState) {
            stateType = this.props.game.gameState;
        } else {
            stateType = this.props.game.modifyGameState
        }

        return (
            <>
                <div className={`scoreboard scoreboard-league ${this.props.display.showLeagueScoreboard ? "active" : "inactive"}`}>
                    <div id='score-grid' className="score-grid">
                        <table>
                            <thead>
                                <tr>
                                    <th></th><th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>6</th><th>7</th><th>8</th><th>9</th><th>10</th><th>Total</th>
                                </tr>
                            </thead>
                            {this.renderTeams(stateType)}
                        </table>
                    </div>
                    { this.props.game.gameEnded
                        ? 
                            <EmailScores gameId={this.props.game.gameId} game={this.props.game.game} />
                        :
                            <div className="actions" style={{position: "absolute", left: "24%", bottom: "0"}}>
                                <div className="btn btn-outline btn-cancel" onClick={() => Display.toggleLeagueScoreboard(false)}>Cancel</div>
                                <div className="btn btn-gold btn-save" onClick={this.updateScoresAndClose}>Save &amp; Return</div>
                            </div>
                    }
                </div>
                <Modal18Keypad 
                    showModalKeypad18={this.props.display.showModalKeypad18}
                    editPlayerScore={this.editPlayerScore}
                    canRemoveScore={this.state.canRemoveScore} 
                    removeScoreAction={this.removePlayerScore} />
            </>
        )
    }
}