import React from 'react';
import Game from '../lib/Game';

export default class LeaguePlayer extends React.Component {

    setCurrentThrower = () => {
        // Evaluate where the thrower we are switching to has completed the game
        let completed = Game.playerGameStatus(this.props.player, this.props.game)
        if (completed) {
            this.props.setCompletedMessage(completed, this.props.player.name);
        }

        Game.setCurrentThrower(this.props.player);
        Game.setCurrentTeam(this.props.team);

        this.props.sendPlayerChange();
    }

    render() {
        return (
            <li className={`${this.props.currentThrower && this.props.currentThrower.id === this.props.id ? "selected" : ""} ${this.props.game && this.props.game.game ? Game.getPlayersClass(this.props.game) : ''}`} onClick={this.setCurrentThrower}>
                {this.props.name}
                { Game.showPlayerScores(this.props.game) &&
                    <div className='score-display'>{Game.getPlayerScore(this.props.player, this.props.gameName, this.props.game)}</div>
                }
            </li>
        )
    }
}