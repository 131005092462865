import React from 'react';
import Game from '../lib/Game';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

const DragHandle = sortableHandle(({ value }) => {
    return (
        <span>
            <span className="value">{value.name}</span>
            <i className="icon-grip"></i>
        </span>
    );
});

const SortableItem = sortableElement(({ value, remove, inGame }) => {
    return (
        <li className="reorder" style={{ zIndex: 999 }}>
            { !inGame && <i className="icon-remove" onClick={() => remove(value)}></i> }
            <DragHandle value={value} />
        </li>
    );
});

const SortableContainer = sortableContainer(({ children }) => {
    return <ul>{children}</ul>;
});

export default class GameSetupSelectedPlayers extends React.Component {

    onSortEnd = (sort) => {
        Game.sortPlayer(sort.oldIndex, sort.newIndex)
    }

    removePlayer = (player) => {
        Game.removePlayerFromTeam(player);
    }

    render = () => {
        return (
            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                {
                    this.props.players.map((player, index) => {
                        return (
                            <SortableItem
                                key={index}
                                index={index}
                                value={player}
                                remove={this.removePlayer} 
                                inGame={this.props.inGame} />
                        )
                    })
                }
            </SortableContainer>
        );
    }
}