import React from 'react';
import LeagueStyle from '../lib/LeagueStyle';

export default class LeaguePlayerScores extends React.Component {

    // Determine if this score can be removed from the game.
    // It must the last actual throw for this player
    canRemoveScore = (index, throwCount) => {
        return index+1 === throwCount;
    }

    evalCanEditScore = (index, player) => {
        // Allow players to edit/create scores only for one more than they currently have
        if (index === 0 || (index - player.throws.length + 1 <= 1) ) {
            if (index >= player.throws.length) {
                this.props.setPlayerScore(index, this.props.playerData.id, false, false);
            } else {
                this.props.setPlayerScore(index, this.props.playerData.id, true, this.canRemoveScore(index, player.throws.length));
            }
        }
    }

    evalBackgroundColor = (throwNo) => {
        let scoreForThrow = LeagueStyle.getThrowScoreForPlayer(throwNo, this.props.playerData);
        if (scoreForThrow === 6) {
            return 'red-background';
        } else if (scoreForThrow === 8) {
            return 'blue-background';
        } else {
            return ''
        }
    }


    render() {
        return (
            <tr className="player">
                <th>{this.props.name}</th>
                <td className={`td-ls ${this.evalBackgroundColor(0)}`} onClick={() => this.evalCanEditScore(0, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(0, this.props.playerData)}</td>
                <td className={`td-ls ${this.evalBackgroundColor(1)}`} onClick={() => this.evalCanEditScore(1, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(1, this.props.playerData)}</td>
                <td className={`td-ls ${this.evalBackgroundColor(2)}`} onClick={() => this.evalCanEditScore(2, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(2, this.props.playerData)}</td>
                <td className={`td-ls ${this.evalBackgroundColor(3)}`} onClick={() => this.evalCanEditScore(3, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(3, this.props.playerData)}</td>
                <td className={`td-ls midway ${this.evalBackgroundColor(4)}`} onClick={() => this.evalCanEditScore(4, this.props.playerData)}>{LeagueStyle.getThrowScoreForPlayer(4, this.props.playerData)}</td>
                <td className={`td-ls ${this.evalBackgroundColor(5)}`} onClick={() => this.evalCanEditScore(5, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(5, this.props.playerData)}</td>
                <td className={`td-ls ${this.evalBackgroundColor(6)}`} onClick={() => this.evalCanEditScore(6, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(6, this.props.playerData)}</td>
                <td className={`td-ls ${this.evalBackgroundColor(7)}`} onClick={() => this.evalCanEditScore(7, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(7, this.props.playerData)}</td>
                <td className={`td-ls ${this.evalBackgroundColor(8)}`} onClick={() => this.evalCanEditScore(8, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(8, this.props.playerData)}</td>
                <td className={`td-ls ${this.evalBackgroundColor(9)}`} onClick={() => this.evalCanEditScore(9, this.props.playerData)}>       {LeagueStyle.getThrowScoreForPlayer(9, this.props.playerData)}</td>
                <td className="final team gray-bg">{LeagueStyle.getTotalScoreForPlayer(this.props.playerData)}</td>
            </tr>
        )
    }

}


